

































import {LifecycleInterface} from '@/shared/interfaces/store/homepage/lifecycle.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeLifecycle extends Vue {

  get lifecycle(): LifecycleInterface {
    return HomepageStore.lifecycle;
  }

}
